<script lang="ts">
  import { replay, getNiceAffiliateLink } from './currentConfetti';
  import { ConfettiP5 } from 'svelte-confetti-p5';
  import ConfettiBurst from '../vendor/svelte-canvas-confetti/ConfettiBurst.svelte';
  import ConfettiCannon from '../vendor/svelte-canvas-confetti/ConfettiCannon.svelte';
  import { random } from '../vendor/svelte-canvas-confetti/utils/random';
  import { onMount, tick } from 'svelte';
  import { browser } from '$app/environment';
  import type { ConfettiDoc } from '../db/ConfettiDoc';
  import Watermark from './Watermark.svelte';
  import Delayed from '../components/elements/Delayed.svelte';
  import type { ConfettiAnimation } from './ConfettiAnimation.types';

  import { isEqual } from 'underscore';
  import { confetti_env_local } from '../helpers/confetti_env';
  import { minMaxBy1 } from './minMaxBy1';
  import WaitOneFrame from '../components/elements/WaitOneFrame.svelte';
  import RainP5ConfettiStyle from './ConfettiStyles/RainP5ConfettiStyle.svelte';
  import FireworksCanvasConfettiStyle from './ConfettiStyles/FireworksCanvasConfettiStyle.svelte';
  import PopCanvasConfettiStyle from './ConfettiStyles/PopCanvasConfettiStyle.svelte';
  import SprayCanvasConfettiStyle from './ConfettiStyles/SprayCanvasConfettiStyle.svelte';

  export let lang: string;
  export let rtl: boolean;

  export let position: 'absolute' | 'fixed' = 'fixed';
  export let overrideShowWatermark: boolean | null = null;
  export let confettiObject: ConfettiDoc;
  export let play = true;

  let w = 0;
  let h = 0;

  // let initialHeight: number;

  // const handleResize = async () => {
  //   console.log('handleResize');

  //   // This fixes annoying jumps in mobile but still maintains the resize if needed
  //   if (Math.abs(h - initialHeight) > 100) {
  //     initialHeight = window.innerHeight;
  //     $replay++;
  //   }
  // };

  $: {
    if ($replay > 0) {
      play = true;
    }
  }

  onMount(() => {
    if (!browser) return;
    // initialHeight = window.innerHeight;
    // handleResize();
  });

  /**
   * This will prevent the animation to restart
   * when $userDoc or $confettiDocs are updated
   * (the update happens because userDoc lastVisit is updated, for example)
   */
  let animation: ConfettiAnimation | undefined;
  $: {
    if (!isEqual(animation, confettiObject.animation)) animation = confettiObject.animation;
  }

  $: rainDensityMultiplier = minMaxBy1(animation?.density?.[0], 1.7, 0.3, 1, true, 2.05);
  $: cannonDensity = minMaxBy1(animation?.density?.[0], 140, 20, 50);
  $: fireworksDensity = minMaxBy1(animation?.density?.[0], 100, 13, 25, true);
  $: rainSizes = [
    minMaxBy1(animation?.size?.[0], 8.5, 4, 5),
    minMaxBy1(animation?.size?.[0], 8.5, 4, 7.5) * 2
  ];
  $: fireworksSize = minMaxBy1(animation?.size?.[0], 8.5, 5, 8);
  $: cannonSizes = {
    w: () =>
      random(
        minMaxBy1(animation?.size?.[0], 10, 10, 18, true, 0.2),
        minMaxBy1(animation?.size?.[0], 16, 4, 10, true, 0.2)
      ),
    h: () =>
      random(
        minMaxBy1(animation?.size?.[0], 7, 4, 6, true, 0.2),
        minMaxBy1(animation?.size?.[0], 7, 3, 4, true, 0.2)
      )
  };
</script>

<!-- <svelte:window on:resize={handleResize} /> -->

{#if overrideShowWatermark === true || (confettiObject?.removeWatermark !== true && overrideShowWatermark !== false)}
  <Watermark
    {rtl}
    {lang}
    link={confettiObject?.affiliateInBadge && confettiObject
      ? getNiceAffiliateLink(confettiObject?.affiliateId)
      : undefined}
  />
{/if}

{#if confetti_env_local}
  <pre
    class="fixed bottom-0 left-0 text-[0.9rem] bg-yellow-300 z-[100000] pe-4 pt-4 bg-opacity-90 rounded-se-3xl opacity-70 pointer-events-none
    hidden md:block
    ">
  play: {play}
  w: {w}
  h: {h}
  animation?.density?.[0]: {animation?.density?.[0]}
  rainDensityMultiplier: {rainDensityMultiplier.toFixed(2)}
  cannonDensity: {cannonDensity}
  fireworksDensity: {fireworksDensity.toFixed(2)}
  size: {animation?.size}
  rainSizes: {rainSizes}
  fireworksSize: {fireworksSize.toFixed(2)}
  cannonSizes: {[cannonSizes.w().toFixed(2), cannonSizes.h().toFixed(2)]}
  </pre>
{/if}

<div
  class="confetties-iframe"
  style={`
    -webkit-text-size-adjust: 100% !important;
    tab-size: 4 !important;
    font-feature-settings: normal !important;
    font-variation-settings: normal !important;
    font-family: "FtMonopol-WEB", system-ui, sans-serif !important;
    line-height: inherit !important;
    direction: ltr !important;
    box-sizing: border-box !important;
    border-width: 0 !important;
    border-style: solid !important;
    border-color: #e5e7eb !important;
    pointer-events: none !important;
    position: ${position} !important;
    bottom: 0px !important;
    left: 0px !important;
    right: 0px !important;
    top: 0px !important;
    z-index: 99999999999999999999999999999999999 !important;
    height: 100% !important;
    width: 100% !important;
    `}
>
  <div
    bind:clientWidth={w}
    bind:clientHeight={h}
    style="position:absolute; width:100%; height:100%;left:0;top:0;"
  >
    {#if play}
      {#if w && h}
        {#key animation}
          {#key $replay}
            <WaitOneFrame>
              {#if animation?.style === 'rain-p5'}
                <RainP5ConfettiStyle {animation} {w} {h} {rainDensityMultiplier} {rainSizes} />
              {/if}

              {#if animation?.style === 'fireworks-canvas'}
                <FireworksCanvasConfettiStyle
                  {animation}
                  {w}
                  {h}
                  {fireworksDensity}
                  {fireworksSize}
                />
              {/if}

              {#if animation?.style === 'pop-canvas'}
                <PopCanvasConfettiStyle {animation} {w} {h} {cannonDensity} {cannonSizes} />
              {/if}

              {#if animation?.style === 'spray-canvas'}
                <SprayCanvasConfettiStyle {animation} {w} {h} />
              {/if}
            </WaitOneFrame>
          {/key}
        {/key}
      {/if}
    {/if}
  </div>
</div>

{@html `<style>
  .p5Canvas {
    visibility: visible !important;
  }
</style>`}
