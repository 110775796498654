<script lang="ts">
  import { fly } from 'svelte/transition';
  import { hideWatermark } from './currentConfetti';
  import { env_confetti_domain } from '../helpers/confetti_env';
  import { onMount, tick } from 'svelte';
  import { isLangRtl } from '../i18n/inlang_rtl';
  import { get_html_language_code } from '../helpers/getHtmlLanguageCode';

  export let link: string = '';
  export let rtl: boolean = false;
  export let lang: string;

  onMount(async () => {
    lang = get_html_language_code(lang);
    if (lang) rtl = isLangRtl(lang);
  });

  let w: any;
  $: isDesktop = w >= 920;
</script>

<svelte:window bind:innerWidth={w} />


{#if !$hideWatermark}
  <a
    transition:fly={{ y: 30, opacity: 1 }}
    href={link?.length ? link : env_confetti_domain}
    target="_blank"
    style={`    -webkit-text-size-adjust: 100%  !important;
  font-variation-settings: initial !important;
  tab-size: 4  !important;
  font-feature-settings: normal  !important;
  font-variation-settings: normal  !important;
  font-family: "FtMonopol-WEB", system-ui, sans-serif  !important;
  --vh: 7.36px  !important;
  --real-vh: calc(var(--vh, 1vh) * 100)  !important;
  line-height: inherit  !important;
  direction: ${rtl ? 'rtl' : 'ltr'} !important;
  box-sizing: border-box  !important;
  border-width: 0  !important;
  border-style: solid  !important;
  border-color: #e5e7eb  !important;
  --tw-border-spacing-x: 0  !important;
  --tw-border-spacing-y: 0  !important;
  --tw-translate-x: 0  !important;
  --tw-translate-y: 0  !important;
  --tw-rotate: 0  !important;
  --tw-skew-x: 0  !important;
  --tw-skew-y: 0  !important;
  --tw-scale-x: 1  !important;
  --tw-scale-y: 1  !important;
  --tw-pan-x:   !important;
  --tw-pan-y:   !important;
  --tw-pinch-zoom:   !important;
  --tw-scroll-snap-strictness: proximity  !important;
  --tw-ordinal:   !important;
  --tw-slashed-zero:   !important;
  --tw-numeric-figure:   !important;
  --tw-numeric-spacing:   !important;
  --tw-numeric-fraction:   !important;
  --tw-ring-inset:   !important;
  --tw-ring-offset-width: 0px  !important;
  --tw-ring-offset-color: #fff  !important;
  --tw-ring-color: rgb(59 130 246 / 0.5)  !important;
  --tw-ring-offset-shadow: 0 0 #0000  !important;
  --tw-ring-shadow: 0 0 #0000  !important;
  --tw-shadow: 0 0 #0000  !important;
  --tw-shadow-colored: 0 0 #0000  !important;
  --tw-blur:   !important;
  --tw-brightness:   !important;
  --tw-contrast:   !important;
  --tw-grayscale:   !important;
  --tw-hue-rotate:   !important;
  --tw-invert:   !important;
  --tw-saturate:   !important;
  --tw-sepia:   !important;
  --tw-drop-shadow:   !important;
  --tw-backdrop-blur:   !important;
  --tw-backdrop-brightness:   !important;
  --tw-backdrop-contrast:   !important;
  --tw-backdrop-grayscale:   !important;
  --tw-backdrop-hue-rotate:   !important;
  --tw-backdrop-invert:   !important;
  --tw-backdrop-opacity:   !important;
  --tw-backdrop-saturate:   !important;
  --tw-backdrop-sepia:   !important;
  text-decoration: inherit  !important;
  position: fixed !important;
  bottom: 0px !important;
  left: 5% !important;
  z-index: 2147483647 !important;
  border-top-left-radius: 0.5rem !important;
  border-top-right-radius: 0.5rem !important;
  --tw-bg-opacity: 1 !important;
  background-color: rgb(0 0 0 / var(--tw-bg-opacity)) !important;
  padding-left: 0.7em !important;
  padding-right: 0.7em !important;
  padding-top: 0.2em !important;
  padding-bottom: 0.2em !important;
  font-size: ${isDesktop ? '16px' : '14px'} !important;
  font-weight:400  !important;
  --tw-text-opacity: 1 !important;
  color: rgb(255 255 255 / var(--tw-text-opacity)) !important;`}
  >
    <span>
      <!-- {i('watermark.text1')} -->
      {#if lang === 'he'}
        הקונפטי באדיבות
      {:else}
        Animation by
        <!-- Free Animation by  -->
      {/if}
    </span>
    <strong style="font-weight:700;" class="font-bold">ConfettiPage.com</strong>
  </a>
{/if}
