import { dev } from '$app/environment';
import { derived, writable, type Readable, type Writable, get } from 'svelte/store';
import prependHttp from 'prepend-http';
import { tick } from 'svelte';
import { trackEvent } from '$lib/modules/trackEvent';
import { getDomain } from '../../../../lib/helpers/isCurrentWebsite';

// export const previewUrlInput = writable(dev ? '' : '');
export const previewUrlInput = writable(dev ? 'demetralambros.com' : '');
// export const previewUrlInput = writable(dev ? 'blue.cc' : '');
// export const previewUrlInput = writable(dev ? 'https://www.wegriddd.com/' : '');
// export const previewUrlInput = writable(dev ? 'https://fontips.com/' : '');
export const previewUrl = writable('');
export const niceUrl = derived(previewUrl, ($previewUrl) => {
  // return $previewUrl ? new URL($previewUrl).host : '';
  return $previewUrl ? getDomain($previewUrl) : '';
});

export const showEditUrlForm = writable(true);

export const forcePreviewScreenshot = writable(false);
export const previewScreenshotURL = derived(previewUrl, ($previewUrl) => {
  return screenshotURL($previewUrl);
});

export const screenshotURL = (url: string, w = 1200, h: number | null = null) => {
  // return `https://site-screenshot-api.onrender.com/take?url=${$previewUrl}&width=1024&height=768&zoom=100`;
  const auth = '67497-4874b446276eb76c6ee94b6c2442bc25';

  return `//image.thum.io/get/width/${w}${h ? `/crop/${h}` : ''}/auth/${auth}/${url}`;
};

export const checkUrlStatus: Writable<'unstarted' | 'loading' | 'done'> = writable('unstarted');

export const checkPreviewUrl: Readable<Function> = derived(
  [previewUrlInput],
  ([$previewUrlInput]) =>
    async () => {
      checkUrlStatus.set('unstarted');
      if (!$previewUrlInput) throw new Error('$previewUrlInput is empty');

      const _previewUrl = prependHttp($previewUrlInput);

      previewUrl.set(_previewUrl);
      console.log('$previewUrl = ', _previewUrl);
      checkUrlStatus.set('loading');

      const res = await fetch(`/api/check-url`, {
        method: 'POST',
        headers: {
          'Content-Type': 'content/type'
        },
        body: JSON.stringify({
          url: _previewUrl
        })
      });
      console.log('res', res);
      const body = await res.json();
      console.log('body', body);

      if (body.replaceUrl && $previewUrlInput !== body.url) {
        trackEvent('Auto Replace Url With WWW', {
          oldUrl: $previewUrlInput,
          newUrl: body.url
        });
        console.log('replaceUrl (previewUrlInput) with: body.url: ', body.url);
        previewUrlInput.set(body.url);
        previewUrl.set(prependHttp(body.url));
      }
      if (body.tryScreenshot === true) {
        forcePreviewScreenshot.set(true);
        checkUrlStatus.set('done');
        showEditUrlForm.set(false);
        return true;
      } else {
        forcePreviewScreenshot.set(false);
      }

      if (res.status === 200 && body.canIframe === true) {
        checkUrlStatus.set('done');
        showEditUrlForm.set(false);
        return true;
      } else {
        trackEvent('checkPreviewUrl() error', {
          $previewUrlInput,
          error: body.error
        });

        throw new Error(body.error);
      }
    }
);
