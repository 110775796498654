<script lang="ts">
  import type { ConfettiAnimation } from '../ConfettiAnimation.types';
  import { random } from '../../vendor/svelte-canvas-confetti/utils/random';
  import ConfettiBurst from '../../vendor/svelte-canvas-confetti/ConfettiBurst.svelte';
  import { responsiveParticleAmount, responsiveParticleSize } from '../ConfettiAnimation.functions';

  export let animation: ConfettiAnimation;
  export let w: number;
  export let h: number;
  export let fireworksDensity: number;
  export let fireworksSize: number;

  $: amount = responsiveParticleAmount(fireworksDensity);
  $: size = responsiveParticleSize(fireworksSize);
</script>

{#if animation.amount === 'few'}
  {#each Array.from({ length: 3 }, (_, i) => random(i)) as delay, i (i)}
    <ConfettiBurst
      origin={[random((w / 4) * 3, w / 4), random((h / 4) * 2, h / 4)]}
      particleCount={amount}
      styles={animation?.pallete}
      onCreate={(p) => ({ ...p, delay, w: size, h: size })}
    />
  {/each}
{/if}
{#if animation.amount === 'some'}
  {#each Array.from({ length: 5 }, (_, i) => random(i)) as delay, i (i)}
    <ConfettiBurst
      origin={[random((w / 4) * 3, w / 4), random((h / 4) * 2, h / 4)]}
      particleCount={amount}
      styles={animation.pallete}
      onCreate={(p) => ({ ...p, delay, w: size, h: size })}
    />
  {/each}
{/if}
{#if animation.amount === 'many'}
  {#each Array.from({ length: 10 }, (_, i) => random(i)) as delay, i (i)}
    <ConfettiBurst
      origin={[random((w / 4) * 3, w / 4), random((h / 4) * 2, h / 4)]}
      particleCount={amount}
      styles={animation.pallete}
      onCreate={(p) => ({ ...p, delay, w: size, h: size })}
    />
  {/each}
{/if}
