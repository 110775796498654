<script lang="ts">
  import { ConfettiP5 } from 'svelte-confetti-p5';
  import type { ConfettiAnimation } from '../ConfettiAnimation.types';
  import { responsiveParticleSize } from '../ConfettiAnimation.functions';

  export let animation: ConfettiAnimation;
  export let w: number;
  export let h: number;
  export let rainDensityMultiplier: number;
  export let rainSizes: any;

  $: minSize = responsiveParticleSize(rainSizes[0]);
  $: maxSize = responsiveParticleSize(rainSizes[1]);
</script>

<div class="confetti-p5-container">
  <!-- {#if confettiObject.animation.duration === 'shorter'}
  <ConfettiP5
    {w}
    {h}
    position="absolute"
    colors={confettiObject.animation.pallete}
    amount={80}
    power={10}
    spacing={2}
    weight={140}
    flip={10}
  />
{/if} -->
  {#if animation.duration === 'short'}
    <ConfettiP5
      {w}
      {h}
      position="absolute"
      colors={animation.pallete}
      amount={120 * rainDensityMultiplier}
      power={15}
      spacing={4}
      {minSize}
      {maxSize}
    />
  {/if}
  {#if animation.duration === 'medium'}
    <ConfettiP5
      {w}
      {h}
      position="absolute"
      colors={animation.pallete}
      amount={120 * rainDensityMultiplier}
      {minSize}
      {maxSize}
    />
  {/if}
  {#if animation.duration === 'long'}
    <ConfettiP5
      {w}
      {h}
      position="absolute"
      colors={animation.pallete}
      power={10}
      spacing={50}
      amount={420 * rainDensityMultiplier}
      {minSize}
      {maxSize}
    />
  {/if}
  {#if animation.duration === 'infinite'}
    <ConfettiP5
      {w}
      {h}
      amount={100 * rainDensityMultiplier}
      position="absolute"
      colors={animation.pallete}
      loop
      {minSize}
      {maxSize}
    />
  {/if}
</div>
