<script lang="ts">
  import type { ConfettiAnimation } from '../ConfettiAnimation.types';
  import { random } from '../../vendor/svelte-canvas-confetti/utils/random';
  import { shuffle } from '../../helpers/shuffle';
  import ConfettiCannon from '../../vendor/svelte-canvas-confetti/ConfettiCannon.svelte';
  import Delayed from '../../components/elements/Delayed.svelte';
  import { minMaxBy1 } from '../minMaxBy1';
  import { responsiveParticleAmount, responsiveParticleSize } from '../ConfettiAnimation.functions';

  export let animation: ConfettiAnimation;
  export let w: number;
  export let h: number;
  $: amount = responsiveParticleAmount(minMaxBy1(animation?.density?.[0], 140, 20, 50, true, 1.5));
  let delay: number = minMaxBy1(animation?.density?.[0], 10, 35, 10, true, 0.2);

  let sizes = {
    w: () =>
      responsiveParticleSize(
        random(
          minMaxBy1(animation?.size?.[0], 7, 10, 18, true, 1.2),
          minMaxBy1(animation?.size?.[0], 18, 5, 10, true, 1.2)
        )
      ),
    h: () =>
      responsiveParticleSize(
        random(
          minMaxBy1(animation?.size?.[0], 7, 5, 6, true, 1.2),
          minMaxBy1(animation?.size?.[0], 7, 5, 4, true, 1.2)
        )
      )
  };
</script>

{#each shuffle(animation.positions || []) as pos, i}
  {#if animation.sprayStyle === 't'}
    <ConfettiCannon
      origin={[w, 0]}
      styles={animation.pallete}
      angle={30 + 90}
      spread={100}
      force={22}
      particleCount={amount}
      onCreate={(p) => ({ ...p, w: sizes.w(), h: sizes.h(), delay: (delay / 1000) * p.i })}
    />
    <ConfettiCannon
      origin={[0, 0]}
      styles={animation.pallete}
      angle={30}
      spread={100}
      force={22}
      particleCount={amount}
      onCreate={(p) => ({ ...p, w: sizes.w(), h: sizes.h(), delay: (delay / 1000) * p.i })}
    />
  {/if}
  {#if animation.sprayStyle === 'b'}
    <ConfettiCannon
      origin={[0, h]}
      styles={animation.pallete}
      angle={-70}
      spread={30}
      force={37}
      particleCount={amount}
      onCreate={(p) => ({ ...p, w: sizes.w(), h: sizes.h(), delay: (delay / 1000) * p.i })}
    />
    <ConfettiCannon
      origin={[w, h]}
      styles={animation.pallete}
      angle={-180 + 70}
      spread={30}
      force={37}
      particleCount={amount}
      onCreate={(p) => ({ ...p, w: sizes.w(), h: sizes.h(), delay: (delay / 1000) * p.i })}
    />
  {/if}

  {#if animation.sprayStyle === 'm'}
    <ConfettiCannon
      origin={[w / 2, h + 20]}
      styles={animation.pallete}
      angle={-90}
      spread={40}
      force={40}
      particleCount={amount * 1.7}
      onCreate={(p) => ({ ...p, w: sizes.w(), h: sizes.h(), delay: (delay / 1000) * p.i })}
    />
  {/if}

  {#if animation.sprayStyle === 'fr'}
    {#each [1, 2, 3, 4, 5] as item, i}
      <Delayed ms={i === 0 ? 0 : i * 200}>
        <ConfettiCannon
          origin={[((w / (5 - 1)) * i * -1 + w) * 0.7 + 0.15 * w, h]}
          styles={animation.pallete}
          angle={-90}
          spread={10}
          force={30}
          particleCount={amount * 0.5}
          onCreate={(p) => ({ ...p, w: sizes.w(), h: sizes.h(), delay: (delay / 1000) * p.i })}
        />
      </Delayed>
    {/each}
  {/if}

  {#if animation.sprayStyle === 'fl'}
    {#each [1, 2, 3] as item, i}
      <Delayed ms={i === 0 ? 0 : i * 220}>
        <ConfettiCannon
          origin={[(w / (3 - 1)) * i * 0.7 + 0.15 * w, h]}
          styles={animation.pallete}
          angle={-90}
          spread={10}
          force={30}
          particleCount={amount * 0.5}
          onCreate={(p) => ({ ...p, w: sizes.w(), h: sizes.h(), delay: (delay / 2 / 1000) * p.i })}
        />
      </Delayed>
    {/each}
  {/if}
{/each}
