import { readonly, writable, type Readable, type Writable } from 'svelte/store';

export function isLangRtl(lang: string) {
  if (['he'].includes(lang)) return true;
  return false;
}

/**
 * Inlang RTL
 */
const _rtl: Writable<boolean> = writable();

export const initRtl = (language: string) => _rtl.set(isLangRtl(language));

export const rtl = readonly(_rtl);

export function getLanguageName(tag: string) {
  let languageNames = new Intl.DisplayNames([tag], { type: 'language' });
  return languageNames.of(tag);
}
