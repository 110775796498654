import { derived, readable, type Readable } from 'svelte/store';
import { getRandom } from '../helpers/getRandom';
import { randomIntBetween } from '../helpers/randomIntBetween';
import {
  currentAnimationAmount,
  currentAnimationDensity,
  currentAnimationDuration,
  currentAnimationPositions,
  currentAnimationSize,
  currentAnimationSprayStyle,
  currentAnimationStyle,
  currentPallete,
  persistedConfetti
} from './currentConfetti';
import {
  animationAmounts,
  animationDurations,
  type AnimationPosition,
  animationStyles,
  type AnimationDuration,
  animationPositions,
  type AnimationPallete,
  DENSITY_PIPS,
  SIZE_PIPS,
  animationSprayStyles,
  type AnimationStyle
} from './ConfettiAnimation.types';
import { defaultColorPalletes } from '../components/elements/DefaultColorPalletes';
import { previewUrl, previewUrlInput } from '../../routes/[lang]/(app)/start/urlStore';

export const randomizePallete: Readable<VoidFunction> = derived(
  currentPallete,
  ($currentPallete) => {
    return () => {
      const otherPalletes: AnimationPallete[] = defaultColorPalletes.filter(
        (pallete) => pallete.join('') !== $currentPallete.join('')
      );
      currentPallete.set(<AnimationPallete>getRandom(otherPalletes));
    };
  }
);

export const randomizeAmount: Readable<VoidFunction> = derived(
  currentAnimationAmount,
  ($currentAnimationAmount) => {
    return () => {
      const other = animationAmounts.filter((pos) => pos !== $currentAnimationAmount);
      currentAnimationAmount.set(getRandom(other));
    };
  }
);
export const randomizeDuration: Readable<VoidFunction> = derived(
  currentAnimationDuration,
  ($currentAnimationDuration) => {
    return () => {
      const other = animationDurations.filter((pos) => pos !== $currentAnimationDuration);
      currentAnimationDuration.set(getRandom(other));
    };
  }
);

export const randomizePositions: Readable<VoidFunction> = derived(
  currentAnimationPositions,
  ($currentAnimationPositions) => {
    return () => {
      const amount = randomIntBetween(1, 3);
      // console.log('amount', amount);

      const newPositions: AnimationPosition[] = [];
      [...Array(amount).keys()].forEach((i) => {
        const unusedPositions = animationPositions.filter((pos) => !newPositions?.includes(pos));
        newPositions.push(getRandom(unusedPositions));
      });

      currentAnimationPositions.set(newPositions);
    };
  }
);

export const randomizeDensity: Readable<VoidFunction> = derived(
  currentAnimationDensity,
  ($currentAnimationDensity) => () => {
    let newDensity: number;
    do {
      newDensity =
        Math.round((randomIntBetween(0, DENSITY_PIPS - 1) / (DENSITY_PIPS - 1)) * 100) / 100;
      console.log({ newDensity, oldDensity: $currentAnimationDensity[0] });
    } while (newDensity === $currentAnimationDensity[0]);
    currentAnimationDensity.set([newDensity]);
  }
);
export const randomizeSize: Readable<VoidFunction> = derived(
  currentAnimationSize,
  ($currentAnimationSize) => () => {
    let newSize: number;
    do {
      newSize = Math.round((randomIntBetween(0, SIZE_PIPS - 1) / (SIZE_PIPS - 1)) * 100) / 100;
      console.log({ newSize, oldSize: $currentAnimationSize[0] });
    } while (newSize === $currentAnimationSize[0]);
    currentAnimationSize.set([newSize]);
  }
);
export const randomizeSprayStyle: Readable<VoidFunction> = derived(
  currentAnimationSprayStyle,
  ($currentAnimationSprayStyle) => {
    return () => {
      const other = animationSprayStyles.filter((style) => style !== $currentAnimationSprayStyle);
      currentAnimationSprayStyle.set(getRandom(other));
    };
  }
);

export const randomizeAnimation: Readable<VoidFunction> = derived(
  [
    currentAnimationStyle,
    randomizePositions,
    randomizeAmount,
    randomizeDensity,
    randomizeSize,
    randomizeSprayStyle
  ],
  ([
    $currentAnimationStyle,
    $randomizePositions,
    $randomizeAmount,
    $randomizeDensity,
    $randomizeSize,
    $randomizeSprayStyle
  ]) => {
    return () => {
      const otherStyles = <
        AnimationStyle[] // Increase the spray-canvas potential by 1.5
      >[...animationStyles, ...animationStyles, 'spray-canvas'].filter((style) => style !== $currentAnimationStyle);
      currentAnimationStyle.set(getRandom(otherStyles));
      $randomizePositions();
      $randomizeAmount();
      $randomizeSprayStyle();

      // Extra for fun
      $randomizeDensity();
      $randomizeSize();
      currentAnimationDuration.set(<AnimationDuration>getRandom([...animationDurations]));
    };
  }
);

export const randomizeEverything: Readable<VoidFunction> = derived(
  [randomizePallete, randomizeAnimation, randomizeDensity, randomizeSize],
  ([$randomizePallete, $randomizeAnimation, $randomizeDensity, $randomizeSize]) =>
    () => {
      $randomizePallete();
      $randomizeAnimation();
      $randomizeDensity();
      $randomizeSize();
    }
);

export const retrieveFromPersistedConfetti: Readable<VoidFunction> = derived(
  persistedConfetti,
  ($persistedConfetti) => () => {
    if ($persistedConfetti) {
      console.log('$persistedConfetti', $persistedConfetti);
      if ($persistedConfetti.animation) {
        currentPallete.set($persistedConfetti.animation.pallete);
        currentAnimationStyle.set($persistedConfetti.animation.style);
        currentAnimationPositions.set($persistedConfetti.animation.positions);
        currentAnimationDuration.set($persistedConfetti.animation.duration);
        currentAnimationAmount.set($persistedConfetti.animation.amount);

        // Added later
        if ($persistedConfetti?.animation?.density)
          currentAnimationDensity.set($persistedConfetti.animation.density);
        if ($persistedConfetti?.animation?.size)
          currentAnimationSize.set($persistedConfetti.animation.size);
        if ($persistedConfetti?.animation?.sprayStyle)
          currentAnimationSprayStyle.set($persistedConfetti.animation.sprayStyle);

        if ($persistedConfetti.site) previewUrlInput.set($persistedConfetti.site);
      }
      if ($persistedConfetti.site) previewUrl.set($persistedConfetti.site);
    }
  }
);

export function responsiveParticleSize(number: number) {
  let x = 1;
  const isMobile = window.innerWidth < 480;
  if (isMobile) x = 0.75;

  return number * x;
}

export function responsiveParticleAmount(number: number) {
  let x = 1;
  const isMobile = window.innerWidth < 480;
  if (isMobile) x = 0.75;

  return number * x;
}
